/**
 * @name: 设备管理-设备调试人员管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 设备管理-设备调试人员管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { IDeviceDebugger, IDeviceDebuggerParams } from './types';

export const deviceDebuggerQueryApi = (params: IDeviceDebuggerParams) => api.get<IPageRes<IDeviceDebugger[]>>('/admin/device/debug/personnel/query', params)

export const deviceDebuggerCreateApi = (data: Partial<IDeviceDebugger>) => api.postJ('/admin/device/debug/personnel/create', data)

export const deviceDebuggerModifyApi = (data: Partial<IDeviceDebugger>) => api.postJ('/admin/device/debug/personnel/modify', data)

export const deviceDebuggerDelApi = (id: string) => api.get('/admin/device/debug/personnel/remove/' + id)
