
/**
 * @name: 设备管理-设备调试人员管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 设备管理-设备调试人员管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  deviceDebuggerQueryApi,
  deviceDebuggerCreateApi,
  deviceDebuggerModifyApi,
  deviceDebuggerDelApi
} from '@/apis/device/debugger'
import {
  IDeviceDebuggerParams,
  IDeviceDebugger
} from '@/apis/device/debugger/types'
import {
  deepCopy
} from '@/utils/common'
import { checkPhone } from '@/constants/validators'
import type { ICrudOption } from "@/types/m-ui-crud"

@Component({})
export default class deviceDebugger extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '用户',
        prop: 'nameAndPhone',
        hide: true,
        addHide: true,
        editHide: true,
        search: true,
        placeholder: '姓名/手机号'
      },
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": 180,
        "addHide": true,
        "editHide": true,
        "viewHide": false
      },
      {
        "label": "姓名",
        "prop": "name",
        "align": "center",
        "overHidden": true,
        maxlength: 20,
        span: 12,
        rules: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'blur'
          },
          {
            validator: (rule: any, value: string, cb: Function) => {
              if (!/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                cb(new Error('姓名只能输入中文、字母、数字'))
                return
              }
              cb()
            },
            trigger: 'blur'
          }
        ]
      },
      {
        "label": "手机号",
        "prop": "phone",
        "align": "center",
        "width": 150,
        "editSlot": true,
        maxlength: 11,
        span: 12,
        rules: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            validator: checkPhone,
            trigger: 'blur'
          }
        ]
      },
      {
        "label": "状态",
        "prop": "status",
        "align": "center",
        "type": "select",
        "search": true,
        "editHide": true,
        "addHide": true,
        slot: true,
        dicData: [
          {
            label: '启用',
            value: 1
          },
          {
            label: '禁用',
            value: 2
          }
        ]
      },
      {
        "label": "备注",
        "prop": "remarks",
        type: 'textarea',
        "align": "center",
        "overHidden": true,
        maxlength: 100,
        showWordLimit: true
      },
      {
        "label": "创建时间",
        "prop": "addTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "overHidden": false,
        "search": true,
        "addHide": true,
        "editHide": true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: IDeviceDebuggerParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length === 2) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    deviceDebuggerQueryApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  rowSave (form: IDeviceDebugger, done: Function, loading: Function) {
    deviceDebuggerCreateApi(form).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IDeviceDebugger, done: Function, loading: Function) {
    deviceDebuggerModifyApi(form).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowDel (row: IDeviceDebugger) {
    deviceDebuggerDelApi(row.id).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  switchStatus (row: IDeviceDebugger, status: number) {
    deviceDebuggerModifyApi({id: row.id, name: row.name, phone: row.phone, status}).then(e => {
      if (e && e.data) {
        this.$message.success('操作成功!')
        this.getList()
      }
    })
  }

  created () {
    this.getList()
  }
}
